body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  flex: 1 1 auto;
  -webkit-user-select: 'none';
  -webkit-touch-callout: 'none';
}

.leaflet-tooltip-pane {
  -webkit-user-select: 'auto';
  -webkit-touch-callout: 'default';
}

.leaflet-popup-content {
	margin: 0;
}

.leaflet-popup-content-wrapper {
	border-radius: 3px;
  padding: 0;
}

.marker-icon-middle {
  background-color: green !important;
  border-color: green !important;
}

.marker-icon-middle::after {
  position: absolute;
  content: "+";
  font-size: 16px;
  font-weight: bold;
  line-height: 10px;
  color: white;
  width: 11px;
  height: 11px;
  left: 0;
  top: 0;
  cursor: pointer;
  pointer-events: none;
}
